import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./KeyFeatures.module.scss"

function PerfectEngagement({ strapiData, predicto, easyfill }) {
  return (
    <>
      <div className={styles.bets}>
        <Container>
          <h2
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className="justify-content-center gap-30">
            {strapiData?.cards?.map((e, i) => (
              <Col lg={3} className={`${styles.colPad} d-flex gap-30`}>
                <div className={styles.card}>
                  <div className={styles.cardImg}>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: e?.title,
                      }}
                    ></h3>
                    <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                  </div>

                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
          </Row>
          <img
            className="pt-5"
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557161_9e2156ba25.svg"
          />
        </Container>
      </div>
      {/* <Container    className={`${easyfill ? styles.easyfillImg : ""}`}>
        <div className={styles.laptopContainer}>
          <img
            src={
              predicto
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1707479483_813bc1bafe.svg"
                : "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557173_657988cfef.webp"
            }
            alt="laptop"
         
          />
        </div>
      </Container> */}
    </>
  )
}

export default PerfectEngagement
