import React from "react"
import * as styles from "./Targets.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

function Targets({strapiData}) {
  return (
    <div className={`${styles.targets} `}>
      <Container>
        <h2
          className={`${styles.heading} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
      </Container>
      <img className={styles.targetImg} src={"https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557385_965544459b.webp"}/>
    </div>
  )
}

export default Targets
