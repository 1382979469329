import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./workflowpredicto.module.scss"

function Workflowpredicto({ strapiData, predicto }) {
  return (
    <div className={styles.predictoContainer}>
      <Container>
        <h2
          className={styles.workflowPredictoHeading}
          // dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        >
          Workflows for Easyfill
        </h2>
        {/* Predicto banner - visible only on desktop */}
        <div className={styles.desktopOnly}>
          {/* <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/easyfill_1_6dbcec0608.webp"
            }
            alt="predicto"
          /> */}
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/easyfill_2_4f89f4de0d.webp"
            }
            alt="predicto"
          />
          <img
            src={
              "https://invozone-backend.s3.us-east-1.amazonaws.com/easyfill_3_4f806266b1.webp"
            }
            alt="predicto"
          />
        </div>

        <div className={styles.mobileTabletOnly}>
          <div className={styles.workflowGridContainerMobile}>
            {/* <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1991422485_b032edd831.svg"
              }
              alt="predicto"
            /> */}
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/easyfill_2_4f89f4de0d.webp"
              }
              alt="predicto"
            />
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/easyfill_3_4f806266b1.webp"
              }
              alt="predicto"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Workflowpredicto
