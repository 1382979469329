import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/predicto/Banner"
import Technologiesweuse from "../../components/invocom/Technologiesweuse"
import AIConsultingService from "../../components/invocom/Introsection"
import KeyFeatures from "../../components/invocom/KeyFeatures"
import Targets from "../../components/easy-fill/Targets"
import Conclusion from "../../components/easy-fill/Conclusion"
import KeyFeature from "../../components/easy-fill/KeyFeatures"
import WorkflowPredicto from "../../components/easy-fill/WorkflowPredicto"
import SEORevamp from "../../components/common/SEO_Revamp"
import KeyChallengesSolution from "../../components/invocom/KeyChalanges"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  console.log("Workflow Data:", data?.allStrapiAiHologram?.nodes[0]?.workflow)
  console.log(
    "Workflow Steps:",
    data?.allStrapiAiHologram?.nodes[0]?.workflow?.workflow_steps?.data
  )
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const easyFill = ({ data }) => {
  const heroSection = data?.strapiPage?.sections[0]
  const consultingService = data?.strapiPage?.sections[1]
  const engagementModel = data?.strapiPage?.sections[2]
  const targets = data?.strapiPage?.sections[3]
  const key = data?.strapiPage?.sections[4]
  const results = data?.strapiPage?.sections[9]
  const advantages = data?.strapiPage?.sections[8]

  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner
        strapiData={heroSection}
        breadCrumb={data?.strapiPage?.buttons}
        easyFill={true}
        showImage={false}
      />
      <Technologiesweuse hideCurve={true} strapiData={heroSection} />
      <AIConsultingService strapiData={consultingService} easyFill={true} />
      <KeyFeatures strapiData={engagementModel} easyfill={true} />
      <Targets strapiData={targets} />
      <KeyChallengesSolution strapiData={key} />
      <WorkflowPredicto />
      <KeyFeature strapiData={advantages} />
      <Conclusion strapiData={results}/>
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query easyFill {
    strapiPage(slug: { eq: "easyfill-ai" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData {
            caseServices {
              invocom_caseStudy {
                skills
              }
              caseStudy
            }
          }
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default easyFill
